import React from "react"
import Layout from "../components/Layout"

const Success = () => {
  return (
    <Layout titlePage="Success">
      <div className="flex-cancel">
        <h1>Poles on Earth</h1>
        <p>
          Thank you for your purchase. An email will be send you for
          instructions.
        </p>
      </div>
    </Layout>
  )
}

export default Success
